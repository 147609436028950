<template>
    <div class="chapter-list flex-column">
        <el-form class="searchForm" ref="searchForm" :model="searchData" :inline="true" @submit.native.prevent>
            <el-form-item label="章节搜索：">
                <el-input v-model="searchData.queryText" clearable placeholder="章节关键词"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="searchHandle">查询</el-button>
            </el-form-item>
        </el-form>
        <div class="chapter-list-head flex-row">
            <el-radio-group cca v-model="searchData.ordertype" @change="orderChangeHandle">
                <el-radio size="mini" :label="1">正序</el-radio>
                <el-radio size="mini" :label="2">倒序</el-radio>
            </el-radio-group>
            <div class="chapter-list-head-rt flex-row">
                <div v-if="editPriceData.selectedRows.length" class="mr10">已勾选<span class="color-red">{{editPriceData.selectedRows.length}}</span>条数据</div>
                <div v-if="sortData.number" class="mr10">你本次已经拖拽调整过 <span class="color-red">{{sortData.number}}</span> 次了</div>
                <el-button
                    :disabled="editPriceData.selectedRows.length === 0"
                    v-if="level_extra.includes('3')"
                    @click="batchEditCreatePrice">批量修改创作价格
                </el-button>
                <el-button
                    :disabled="editPriceData.selectedRows.length === 0"
                    v-if="[1, 2].includes(level)"
                    @click="batchEditPrice">批量修改价格
                </el-button>
                <el-button v-if="[1, 2].includes(level)" @click="addChapter">新增章节</el-button>
                <el-button v-if="sortData.number" type="primary" @click="saveSortHandle">保存排序</el-button>
            </div>
        </div>
        <dragTable
            :data="tableList"
            :border="true"
            :row-class-name="tableRowClassName"
            ref="table"
            dragHandle=".el-icon-move"
            :onEnd="dragEndHandle"
            style="width: 100%"
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" label="选择" align="center" width="50"></el-table-column>
            <el-table-column type="index" label="排序" align="center" width="50"></el-table-column>
            <el-table-column label="章节编号" prop="chapterSN" align="center" width="120">
                <template slot-scope="scope">
                    <chapterFieldEdit :scope="scope" />
                </template>
            </el-table-column>
            <el-table-column label="章节名" prop="chapterName" align="center" width="150">
                <template slot-scope="scope">
                    <chapterFieldEdit :scope="scope" />
                </template>
            </el-table-column>
            <el-table-column label="章节封面图" prop="chapterCover168" align="center" width="150">
                <template slot-scope="scope">
                    <img-preview :src="$config.cdn + $utils.filterImagePath(scope.row.chapterCover168)">
                       <img
                            width="76px"
                            height="38px"
                            :key="$config.cdn + $utils.filterImagePath(scope.row.chapterCover168)"
                            v-lazy="$config.cdn + $utils.filterImagePath(scope.row.chapterCover168)"
                        />
                    </img-preview>
                </template>
            </el-table-column>
            <el-table-column v-if="hasCreatePricePermission" label="创作价格（每格/元）" prop="createPrice" align="center" width="160">
                <template slot-scope="scope">
                    <chapterFieldEdit :scope="scope" />
                </template>
            </el-table-column>
            <el-table-column label="格子数" prop="gridNum" align="center" width="150">
                <template slot-scope="scope">
                    <chapterFieldEdit :scope="scope" />
                </template>
            </el-table-column>
            <el-table-column label="章节类型" prop="chapterType" align="center" width="150">
                <template slot-scope="scope">
                    <span>{{scope.row.chapterType | chapterTypeFilter}}</span>
                </template>
            </el-table-column>
            <el-table-column label="会员专属" prop="isVip" align="center" width="80">
                <template slot-scope="scope">
                    <span>{{scope.row.isVip ? '是' : '否'}}</span>
                </template>
            </el-table-column>
            <el-table-column label="页数" align="center" width="80">
                <template slot-scope="scope">
                    <span>{{scope.row.imageAddr | chapterStrFilter('length')}}</span>
                </template>
            </el-table-column>
            <el-table-column label="金额 最大限制500钻石" prop="chapterPrice" align="center" width="160">
                <template slot-scope="scope">
                    <chapterFieldEdit :scope="scope" />
                </template>
            </el-table-column>
            <el-table-column label="发布平台及时间" prop="publishTask" align="center" width="300">
                <template slot-scope="scope">
                    <div v-for="platform in scope.row.publishTask" :key="platform.platformId" class="platform-col flex-row-between">
                        <div class="platform-col-name">{{formatPlatform(platform.platformId)}}</div>
                        <div>
                            <span class="platform-col-time"> {{ platform.isTiming ? '定时发布' :'立即发布' }}</span>
                            <span class="platform-col-time">({{ platform.publishTime | timeFilter('yyyy-MM-dd hh:mm:ss')}})</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="理由" prop="auditRemark" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="操作" width="420" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" plain @click="previewHandle(scope.row)">预览漫画</el-button>
                    <el-button size="mini" type="primary" :disabled="scope.row.videoID === '' ? true : false" plain @click="previewVideoHandle(scope.row)">预览V漫</el-button>
                    <el-button size="mini" type="info" :disabled="scope.row.showStatus === 0" plain @click="editHandle(scope.row)">修改</el-button>
                    <el-button v-if="scope.row.isDelete" size="mini" type="danger" plain @click="removeHandle(scope.$index, scope.row)">删除</el-button>
                    <el-button size="mini" :type="scope.row.showStatus === 0 ? 'danger': 'warning'" plain @click="displayHandle(scope.$index, scope.row)">{{scope.row.showStatus === 0 ? '上架' : '下架'}}</el-button>
                </template>
            </el-table-column>
            <!-- <el-table-column label="排序" width="80" align="center">
                <template slot-scope="scope">
                    <i class="el-icon-move drag-handle"></i>
                </template>
            </el-table-column> -->
            <el-table-column label="排序值" prop="chapterSN" align="center" width="150">
                <template slot-scope="scope">
                    <chapterSortEdit :scope="scope" @getTableList = "takeSortGetTableList()" />
                </template>
            </el-table-column>
        </dragTable>
        <paginationPlus :layout="'total, prev, pager, next'" :currentPage.sync="pagination.pageIndex" :pageSize.sync="pagination.pageSize" :total="pagination.total" @callback="pageChangeHandle"></paginationPlus>
        <chapterView
            ref="chapterView"
            :comicName="comicName"
            :chapterId.sync="currentChapter.chapterId"
            :comicId="comicId"
        ></chapterView>
        <dialogPlus
            center
            :visible.sync="editPriceData.visible"
            :title="editPriceTitle"
            width="400px"
            :heightFull="false"
            :close-on-click-modal="false"
        >
            <el-form ref="batchPriceForm" :model="editPriceData.data" :rules="editPriceData.rules" label-width="100px">
                <el-form-item label=" " prop="batchPrice">
                    <el-input
                        :key="editPriceData.type"
                        style="width:200px"
                        v-acgn-number="editPriceData.type === 1 ? 0 : 4"
                        v-model.number="editPriceData.data.batchPrice"
                        placeholder="请输入0-500的金额"
                    />
                </el-form-item>
            </el-form>
            <div slot="footer" align="center">
                <el-button @click="closeEditPrice">取消</el-button>
                <el-button type="primary" @click="saveEditPrice">确定</el-button>
            </div>
        </dialogPlus>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-20 14:51:40
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-20 15:11:14
 * @Description: 章节列表
 * @Company: 成都二次元动漫
 */
import chapterFieldEdit from './chapterFieldEdit';
import chapterView from './chapterView';
import dragTable from '@/components/table/dragTable';
import chapterSortEdit from './chapterSortEdit';
import { dataMixin, paginationMixin, dialogMixin, createPriceStaffMixin } from '@/mixins';
import { mapState } from 'vuex';
import dom from '@/libs/dom';
import ImgPreview from '@/components/img-preview';

export default {
    components: {
        dragTable,
        chapterView,
        chapterFieldEdit,
        ImgPreview,
        chapterSortEdit
    },
    props: {
        mainPrice : {
            type: Number,
            default: null
        }
    },
    mixins: [dataMixin, paginationMixin, dialogMixin, createPriceStaffMixin],
    data() {
        return {
            pageLoading: false, // 是否加载章节数据中
            scrollWrap: null, // 滚动容器dom节点
            comicName: null,
            comicId: null,
            pagination: {
                pageSize: 100
            },
            searchData: {
                ordertype: 2,
                queryText: null
            },
            platformList: [],
            tableList: [],
            sortData: {
                comicId: null,
                number: 0,
                list: []
            },
            currentChapter: {},
            editPriceData: { // 批量修改价格弹窗
                visible: false,
                type: 1, // 1：价格，2：创作价格
                btnDisabled: true,
                data: { // 编辑数据价格
                    batchPrice: null
                },
                selectedRows: [], // 选中的行
                rules: {
                    batchPrice: [
                        { required: true, message: '请输入0-500的金额', trigger: 'change' },
                        { type: 'number', min: 0, max: 500, message: '金额最大500', trigger: 'change' }
                    ]
                }
            },
            previewVideoType: 'primary',
            chapterPrice: null // 漫画章节价格
        };
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level, // 1：用户 2：管理 3：超管
            userId: state => state.userInfo.id,
            level_extra: state => state.userInfo.level_extra // [1 创作价格 2 工作效率 3 批量修改创作价格]
        }),
        editPriceTitle: function() {
            return `批量修改${this.editPriceData.type === 2 ? '创作' : ''}价格`;
        }
    },
    created() {
        const { comicId, comicName, comicType } = this.$route.query;
        this.comicName = comicName;
        this.comicId = comicId;
        this.comicType = comicType;
        this.sortData.comicId = comicId;
        this.getComicPlatformList();
        this.getTableList();
    },
    mounted() {
        const that = this;
        // this.scrollWrap = document.querySelector('.chapter-list .el-table__body-wrapper');
        // this.scrollWrap.addEventListener('scroll', () => {
        //     const bodyWrap = dom.children(this.scrollWrap)[0];
        //     if (bodyWrap.clientHeight - 50 < this.scrollWrap.scrollTop + this.scrollWrap.clientHeight) {
        //         const { pageIndex, pageSize, total } = that.pagination;
        //         if (pageIndex === that.$utils.pageTotal(pageSize, total)) return false;
        //         that.nextPage();
        //     }
        // });
    },
    methods: {
        tableRowClassName({row}) {
            if (row.showStatus === 0) {
                return 'unshelve';
            }
        },
        handleSelectionChange(val) {
            this.editPriceData.selectedRows = val;
        },
        closeEditPrice() {
            this.editPriceData.visible = false;
            this.$refs.batchPriceForm.resetFields();
        },
        // 批量修改价格保存
        saveEditPrice() {
            this.$refs.batchPriceForm.validate((valid) => {
                if (!valid) {
                    return;
                }
                const chapterIds = this.editPriceData.selectedRows.map(item => item.chapterId).join(',');
                const { comicId, comicName, authorName, uploaderUid } = this.$route.query;
                const price = this.editPriceData.data.batchPrice;
                const apiUrl = this.editPriceData.type === 1 ? 'batchChapterPrice' : 'batchChapterCreatePrice';
                this.$api(apiUrl, {
                    comicId,
                    chapterIds,
                    price,
                    createPrice: price,
                    sourceObj: { comicId, comicName, authorName, uploaderUid }
                }).then((res) => {
                    this.$message.success('修改成功');

                    // 提交成功后，循环更新本地列表对应字段数据
                    this.editPriceData.selectedRows.forEach(row => {
                        const capter = this.tableList.find(item => item.chapterId === row.chapterId);
                        if (capter && this.editPriceData.type === 1) {
                            capter.chapterPrice = price;
                        } else if (capter && this.editPriceData.type === 2) {
                            capter.createPrice = price;
                        }
                    });

                    this.closeEditPrice();
                });
            });
        },
        // 批量修改价格
        batchEditPrice() {
            this.editPriceData.type = 1;
            this.editPriceData.visible = true;
        },
        // 批量创作修改价格
        batchEditCreatePrice() {
            this.editPriceData.type = 2;
            this.editPriceData.visible = true;
        },
        // 获取漫画类型列表
        getComicPlatformList() {
            return this.$api('getComicPlatformList').then(({ data }) => (this.platformList = data));
        },
        // 格式化平台名称
        formatPlatform(platformId) {
            const platform = this.platformList.find(platform => (platform.id === platformId));
            if (platform) {
                return platform.name;
            }
            return '';
        },
        searchHandle() {
            this.tableList = [];
            this.pageLoading = true;
            // this.scrollWrap.scrollTop = 0;
            setTimeout(() => {
                this.pageLoading = false;
                this.getTableList(1);
            }, 100);
        },
        // 排序类型切换
        orderChangeHandle() {
            this.searchHandle();
        },
        // 预览章节
        previewHandle(currentChapter) {
            this.currentChapter = currentChapter;
            this.chapterIsView = true;
            this.$refs.chapterView.openDialog();
        },
        // 预览短漫频
        previewVideoHandle(info) {
            if(info.videoID !== '') {
              this.$api('getComicVideoPlayAuth', {video_id: info.videoID}).then(res => {
                  window.open(res.data.PlayInfoList.PlayInfo[0].PlayURL, '_blank')
              })
            }
        },
        // 新增章节
        addChapter() {
            const { comicId, comicName, authorName, uploaderUid } = this.$route.query;
            this.$router.push({ name: 'addChapter', query: { comicId, comicName, uploaderUid, authorName, type: 'add', comicType: this.comicType } });
        },
        // 编辑章节
        editHandle({ comicId, chapterId }) {
            const { authorName, uploaderUid } = this.$route.query;
            this.$router.push({ name: 'editComicChapter', query: { type: 'edit', comicName: this.comicName, comicId, uploaderUid, chapterId, authorName, tabType: 2, single: 1, comicType: this.comicType } });
        },
        // 删除章节
        removeHandle(index, row) {
            const { comicId, chapterId } = row;
            const { comicName, authorName, uploaderUid } = this.$route.query;
            this.$utils.confirm('', '确定删除吗？', '删除中...', (action, instance, done) => {
                return this.$api('deleteComicChapter', { comicId, chapterId, sourceObj: { ...row, comicName, authorName, uploaderUid } }).then(res => {
                    this.$message.success('删除成功！');
                    this.tableList.splice(index, 1);
                });
            });
        },
        // 上/下架章节
        displayHandle(index, row) {
            const { comicId, chapterId, showStatus } = row;
            const { comicName, authorName, uploaderUid } = this.$route.query;
            const msg = showStatus === 0 ? '上架' : '下架';
            this.$utils.confirm('', `确定${msg}吗`, `${msg}中...`, (action, instance, done) => {
                return this.$api('displayComicChapter', { comicId, chapterId, showStatus: showStatus === 1 ? 0 : 1, sourceObj: { ...row, comicName, authorName, uploaderUid } }).then(res => {
                    this.$message.success(`${msg}成功！`);
                    row.showStatus = showStatus === 1 ? 0 : 1;
                    this.tableList.splice(index, 1, row);
                });
            });
        },
        // 查询列表
        getTableList(pageIndex) {
            if (pageIndex) this.pagination.pageIndex = pageIndex;
            return this.$api('getComicChapterList', { comicId: this.$route.query.comicId, ...this.searchData, ...this.pagination }).then(res => {
                const { rows, pageIndex, pageSize, total } = res.data;
                this.tableList = rows;
                this.pagination.pageIndex = pageIndex;
                this.pagination.pageSize = pageSize;
                this.pagination.total = total;
                this.pageLoading = false;
            });
        },
        // 统一操作列表查询，refresh是刷新，因为是懒加载所以在刷新的时候需要替换某一页，由于是可以拖拽，所以需要将所有已经加载的页数据都替换了
        getTableListHandle(refresh) {
            const { pageIndex } = this.pagination;
            // 更新
            if (refresh) {
                this.tableList = [];
                // 多页更新
                if (pageIndex > 1) {
                    const arr = Array.from(Array(pageIndex), (v, k) => k);
                    Promise.all(arr.map((item, index) => this.getTableList(index + 1)));
                } else if (pageIndex === 1) { // 单页更新
                    this.getTableList();
                }
            } else { // 不更新，追加
                this.getTableList();
            }
        },
        // 更新排序值，刷新列表
        takeSortGetTableList() {
            this.tableList = [];
            this.sortTemp = true;
            this.getTableList(1);
        },
        // 拖拽结束
        dragEndHandle({ oldIndex, newIndex }) {
            console.log(oldIndex, newIndex)
            if (oldIndex === newIndex) return false;
            this.saveToggleDargTableData(oldIndex, newIndex);
        },
        // 获得变化的数据
        saveToggleDargTableData(oldIndex, newIndex) {
            let mv;
            const min = Math.min(oldIndex, newIndex);
            const len = Math.max(oldIndex, newIndex) - min + 1;
            if (!this.sortData.list.length) {
                this.sortData.list = this.tableList.concat();
            }

            const changeList = this.sortData.list.concat().splice(min, len);
            if (oldIndex < newIndex) {
                mv = changeList.shift();
                changeList.push(mv);
            } else if (oldIndex > newIndex) {
                mv = changeList.pop();
                changeList.unshift(mv);
            }
            ++this.sortData.number;
            this.sortData.list.splice(min, len, ...changeList);
        },
        // 获得变化的数据在总数据的序列
        getDragDataIndex(changeList, oldDataList) {
            const tmpArr = [];
            const { ordertype } = this.searchData;
            changeList.forEach((item, index) => {
                oldDataList.some((v, k) => {
                    // 如果改变后的数据章节id和原始数据章节id相等，且这是他们所对应的下标不相等说明是被更改后的数据
                    if (item.chapterId === v.chapterId && index !== k) {
                        tmpArr.push({
                            chapterId: item.chapterId,
                            ordernum: ordertype === 1 ? index + 1 : this.pagination.total - index
                        });
                        return true;
                    }
                });
            });
            return tmpArr;
        },
        // 保存排序
        saveSortHandle() {
            const that = this;
            this.$msgbox({
                title: '',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                showCancelButton: true,
                center: true,
                message: '确定保存所有的更改操作？',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        instance.confirmButtonText = '提交中...';
                        // 提交改变后的数据
                        const changeList = that.getDragDataIndex(that.sortData.list, that.tableList);
                        const { comicId, comicName, authorName, uploaderUid } = this.$route.query;
                        that.sortData.list = changeList;
                        that.$api('sortComicChapter', { ...that.sortData, comicId, sourceObj: { comicId, comicName, authorName, uploaderUid } }).then(res => {
                            instance.confirmButtonLoading = false;
                            that.$message({
                                type: 'success',
                                message: '更改成功～'
                            });
                            that.resetDataField('sortData');
                            that.getTableListHandle(true);
                            done();
                        }).catch(() => {
                            instance.confirmButtonLoading = false;
                            done();
                        });
                    } else {
                        done();
                    }
                }
            });
        },
        // 下一页
        nextPage() {
            if (this.pageLoading) {
                return;
            }
            this.pageLoading = true;
            this.pagination.pageIndex += 1;
            this.getTableListHandle();
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableListHandle();
        }
    }
};
</script>

<style lang="scss">
    .chapter-list{
        height: 100%;
        .el-table{
            flex-grow: 1;
            .el-table__body-wrapper{
                height: calc(100% - 40px);
                position: relative;
                overflow-y: auto;
                overflow-x: scroll;
            }
            .el-table__row.unshelve {
                background-color: #ccc;
            }
        }
        &-head{
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            &-rt{
                flex-grow: 1;
                justify-content: flex-end;
                align-items: center;
            }
        }
        .el-icon-move{
            font-size: 24px;
            color: #ccc;
            cursor: move;
        }
        .platform-col{
            &-name{

            }
            &-time{
            }
        }
    }
</style>

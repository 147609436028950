<template>
    <div class="chapter-field">
        <div class="flex-row-between">
            <div>{{scope.row[scope.column.property]}}</div>
            <div @click="editHandle"><i class="el-icon-edit color-green"></i></div>
        </div>
        <el-dialog title="排序值修改" :visible.sync="dialogFormVisible">
            <el-form>
                <el-col :span="24">
                    <span class="span_sty">将章节<span class="span_cont">{{this.scope.row.chapterSN + '(' + this.scope.row.chapterName + ')'}}</span>调整到</span>
                </el-col>
                <el-col :span="24">
                    <el-form-item>
                        <el-select v-model="checkChapter" placeholder="请选择活动区域">
                            <el-option
                            v-for="item in chapterList"
                            :key="item.chapterId"
                            :label="item.chapterSN + ' ' + item.chapterName"
                            :value="item.chapterId"></el-option>
                        </el-select>
                        <span class="span_sty">的后面</span>
                    </el-form-item>
                </el-col>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="editChapterSort">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-21 13:33:56
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-18 09:13:59
 * @Description: 章节列表-行内编辑
 * @Company: 成都二次元动漫
 */

export default {
    props: {
        scope: Object
    },
    data() {
        return {
            cloneRowData: null,
            dialogFormVisible: false,
            chapterList: [],
            checkChapter: '',
            comicId: '',
            chapterId: ''
        };
    },
    methods: {
        // 点击编辑
        editHandle() {
            this.dialogFormVisible = true;
            this.cloneRowData = this.$utils.cloneDeep(this.scope.row);
            const { comicId, chapterId } = this.scope.row;
            this.comicId = comicId;
            this.chapterId = chapterId;
            this.getChapterList();
        },

        // 确认修改
        editChapterSort() {
            this.$api('editChapterOrder', { comicId: this.comicId, currChapterId: this.chapterId, targetChapterId: this.checkChapter }).then(res => {
                this.$message.success(`修改成功！`);
                this.dialogFormVisible = false;
                this.$emit('getTableList');
            });
        },

        // 获取章节列表
        getChapterList() {
            this.$api('getComicChapterList', { comicId: this.comicId, pageSize: 9999 }).then(res => {
                this.chapterList = res.data.rows;
                this.chapterList.some(item => {
                    if (item.chapterId === this.chapterId) {
                        this.checkChapter = item.chapterId;
                    }
                    return item.chapterId === this.chapterId;
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .el-icon-edit{
        cursor: pointer;
    }
    .span_sty{
        display: inline-block;
        font-size: 16px;
        margin-bottom: 15px;
        .span_cont{
            display: inline-block;
            color: #409EFF;
            margin: 0 10px;
            font-size: 16px;
        }
    }
</style>
